<con-page-layout>
  <con-page-header header="Set Maintenance Schedule"></con-page-header>
  <con-page-layout-content>
    <mat-error *ngIf="error">{{ error }}</mat-error>
    <form (ngSubmit)="onSubmit()" *ngIf="maintenance" #form="ngForm" novalidate>
      <mat-card>
        <mat-card-content>
          <mat-form-field>
            <input type="text" matInput readonly placeholder="Account Name" [(ngModel)]="maintenance.accountName" name="accountName" maxlength="100"
             #accountName="ngModel" />
          </mat-form-field>

          <mat-form-field>
            <input type="text" matInput readonly placeholder="AUnique" [(ngModel)]="maintenance.aUnique" name="AUnique" maxlength="100"
             #aUnique="ngModel" />
          </mat-form-field>

          <mat-form-field>
            <input type="text" matInput readonly placeholder="Folder Name" [(ngModel)]="maintenance.folderName" name="folderName" maxlength="100"
             #folderName="ngModel" />
          </mat-form-field>

          <mat-form-field>
            <input type="text" matInput readonly placeholder="Folder Region" [(ngModel)]="maintenance.folderRegion" name="folderRegion" maxlength="100"
             #folderRegion="ngModel" />
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Maintenance Start Time"
              [ngxMatDatetimePicker]="startTimePicker"
              [(ngModel)]="maintenance.maintenanceStartTime"
              name="maintenanceStartTime"
              [min]="minDate"
              #maintenanceStartTime="ngModel">
            <mat-datepicker-toggle matPrefix [for]="startTimePicker">
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker #startTimePicker
              [showSpinners]="showSpinners"
              [showSeconds]="showSeconds"
              [stepHour]="stepHour"
              [stepMinute]="stepMinute"
              [stepSecond]="stepSecond"
              [touchUi]="touchUi"
              [color]="color"
              [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker>
            <mat-hint> Maintenance Start Time should be in GMT Timezone and format is mm/dd/yyyy HH:MM:SS</mat-hint>
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Maintenance End Time"
              [ngxMatDatetimePicker]="endTimePicker"
              [(ngModel)]="maintenance.maintenanceEndTime"
              name="maintenanceEndTime"
              [min]="minDate"
              #maintenanceEndTime="ngModel">
            <mat-datepicker-toggle matPrefix [for]="endTimePicker">
            </mat-datepicker-toggle>
            <ngx-mat-datetime-picker #endTimePicker
              [showSpinners]="showSpinners"
              [showSeconds]="showSeconds"
              [stepHour]="stepHour"
              [stepMinute]="stepMinute"
              [stepSecond]="stepSecond"
              [touchUi]="touchUi"
              [color]="color"
              [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker>
            <mat-hint> Maintenance End Time should be in GMT Timezone and format is mm/dd/yyyy HH:MM:SS</mat-hint>
          </mat-form-field>

          <mat-form-field>
            <textarea rows="2" cols="50" matInput placeholder="Maintenance Notes" [(ngModel)]="maintenance.maintenanceNotes" name="maintenanceNotes" maxlength="2000"
              #maintenanceNotes="ngModel" required></textarea>
          </mat-form-field>

        </mat-card-content>
      </mat-card>
      <div fxLayout="row" fxLayoutGap="8px">
        <button mat-raised-button type="submit" color="primary" [disabled]="!isMaintenanceWindowValid()">{{ 'save' | translate }}</button>
        <a mat-raised-button [routerLink]="['/maintenance']">{{ 'cancel' | translate }}</a>
      </div>
    </form>
  </con-page-layout-content>
</con-page-layout>
