<con-page-layout>
  <con-page-header header="Maintenance Schedule"></con-page-header>
  <con-page-layout-content fxLayout="column">
    <div fxLayout="column">
      <div fxLayout="column" fxLayout.gt-sm="row" class="filter">
          <mat-form-field fxFlex="50" appearance="fill" [ngStyle.gt-sm]="{ 'padding-right.px': 10, 'width.px': 10 }" fxFlexGap>
              <mat-label>Filter by Account Name</mat-label>
              <input matInput [(ngModel)]="accountFilter">
          </mat-form-field>
      </div>
      <form #form="ngForm" novalidate>
        <mat-table class="lessons-table mat-elevation-z8" #table [dataSource]="filteredMaintenances$ | async">
          <ng-container matColumnDef="accountName">
            <mat-header-cell *matHeaderCellDef>Account Name</mat-header-cell>
            <mat-cell *matCellDef="let row"><a [routerLink]="[row.solutionId, 'edit']" routerLinkActive="router-link-active" >{{row.accountName}}</a></mat-cell>
          </ng-container>          

          <ng-container matColumnDef="AUnique">
            <mat-header-cell *matHeaderCellDef>AUnique</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.aUnique}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="folderName">
            <mat-header-cell *matHeaderCellDef>Folder Name</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.folderName}}</mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="folderRegion">
            <mat-header-cell *matHeaderCellDef>Folder Region</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.folderRegion}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="maintenanceStartTime">
            <mat-header-cell *matHeaderCellDef>Maintenance Start Time</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.maintenanceStartTime}}</mat-cell>
          </ng-container>  
          
          <ng-container matColumnDef="maintenanceEndTime">
            <mat-header-cell *matHeaderCellDef>Maintenance End Time</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.maintenanceEndTime}}</mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="maintenanceNotes">
            <mat-header-cell *matHeaderCellDef>Maintenance Notes</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.maintenanceNotes}}</mat-cell>
          </ng-container> 
        
          <ng-container cdkColumnDef="remove">
            <mat-header-cell fxFlex="90px" *cdkHeaderCellDef>
            </mat-header-cell>
            <mat-cell fxFlex="90px" *cdkCellDef="let row">
              <button *ngIf="row.maintenanceStartTime || row.maintenanceEndTime" mat-icon-button (click)="removeMaintenance(row.solutionId)">
                <mat-icon [matTooltip]="removeTooltip">{{ removeIcon }}</mat-icon>
              </button>
            </mat-cell>
          </ng-container>  

          <mat-header-row
            *matHeaderRowDef="['accountName', 'AUnique', 'folderName', 'folderRegion', 'maintenanceStartTime', 'maintenanceEndTime', 'maintenanceNotes', 'remove']">
          </mat-header-row>
          <mat-row  
            *matRowDef="let row; columns: ['accountName', 'AUnique', 'folderName', 'folderRegion', 'maintenanceStartTime', 'maintenanceEndTime', 'maintenanceNotes', 'remove'];">
          </mat-row>
        </mat-table>
      </form>
    </div>  
    <div *ngIf="errorMessage$ | async as errorMessage">
        {{ errorMessage }}
    </div>      
  </con-page-layout-content>
</con-page-layout>